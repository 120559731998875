import { useRouter } from '@tanstack/react-router'

import { postCancel } from '@services/workflows'
import type { StatusEnumType, WorkflowStatusType } from '@types'
import { Button } from '@components'

import spinnerSvg from '@assets/spinner-circles.svg'
import exclaimSvg from '@assets/exclaim-circle.svg'
import errorSvg from '@assets/x-circle.svg'

// TODO: Remove partial if we need to handle all statuses
type StatusType<T> = Partial<Record<StatusEnumType, T>>

type ColorTuple = [string, string, string]
//   ^^^^^^^^^^   [bg, text, border/shadow]
const defaultTuple: ColorTuple = ['', 'text-secondary', '']

const colorMap: StatusType<ColorTuple> = {
  // TODO: Verify these statuses
  // 'File Validation Error': defaultTuple,
  // 'Inspection Scheduled': defaultTuple,
  // Complete: defaultTuple,
  // error_skipped: defaultTuple,
  // in_research: defaultTuple,
  // need_info: defaultTuple,
  // no_hit: defaultTuple,
  // stopped: defaultTuple,
  // submit: defaultTuple,
  // prepared: defaultTuple,

  waiting: defaultTuple,
  working: defaultTuple,
  skipped: defaultTuple,
  processing: defaultTuple,
  'need input': defaultTuple,
  complete: ['bg-primary-light', 'text-secondary', 'shadow-primary'],
  error: ['bg-status-red', 'text-secondary-default', 'shadow-red-100'],
  canceled: ['bg-status-red', 'text-secondary-default', 'shadow-red-100'],
}

const messageMap: StatusType<string> = {
  // TODO: Verify these statuses
  // 'File Validation Error': 'File Validation Error ',
  // 'Inspection Scheduled': 'Inspection Scheduled ',
  // Complete: 'Workflow completed ',
  // error_skipped: 'Workflow error skipped ',
  // in_research: 'Workflow in research ',
  // need_info: 'Workflow needs info ',
  // no_hit: 'Workflow no hit ',
  // stopped: 'Workflow stopped ',
  // submit: 'Workflow submitted ',
  // prepared: 'Workflow prepared ',

  working: 'Processing…',
  waiting: 'Processing…',
  processing: 'Processing…',
  complete: 'Workflow completed ',
  error: 'An error occurred ',
  canceled: 'Workflow canceled ',
  'need input': 'Workflow needs input…',
  skipped: 'A node has been skipped ',
}

const getColors = (status: StatusEnumType) =>
  colorMap[status] ?? colorMap['working']!
const getMessage = (status: StatusEnumType) =>
  messageMap[status] ?? messageMap['working']!

export default function WorkflowActionPanel({
  data,
}: {
  data: WorkflowStatusType
}) {
  const router = useRouter()
  const { canceled_date, completed_date, status } = data
  const [bgColor, textColor, shadowColor] = getColors(status || 'working')

  const showDate = status !== 'working'
  const date = completed_date ?? canceled_date
  const showCancelButton =
    status !== 'complete' && status !== 'canceled' && status !== 'Complete'

  const cancelHandler = async () => {
    const res = await postCancel(data.id)
    if (res instanceof Response)
      throw new Error('Error with status code ' + res.status)

    if (res.success) {
      console.info('No errors posting cancel node data')
      router.invalidate()
    } else {
      throw new Error(res.message)
    }
  }
  return (
    <div
      className={`mb-6 flex min-h-[5rem] w-full flex-row items-center gap-4 rounded-md ${bgColor} p-4 shadow-xs ${shadowColor}`}
    >
      <ActionIcon status={status} />

      <p
        className={`${
          status === 'complete' ? 'ml-2' : ''
        } text-sm font-medium ${textColor}`}
      >
        {getMessage(status || 'working')}
        <span>{showDate ? date?.toLocaleDateString() : null}</span>
      </p>
      {showCancelButton && (
        <Button
          variant="text"
          className="ml-auto text-red-500 "
          size="md"
          onClick={() => void cancelHandler()}
        >
          Stop Workflow
        </Button>
      )}
    </div>
  )
}
// TODO: Move to shared??
// TODO: finish support for all statuses
function ActionIcon({ status }: { status?: StatusEnumType }) {
  if (status === 'complete') return null
  if (status === 'working' || !status)
    return (
      <div className="animate-pulse">
        <div className="animate-spin p-2">
          <img src={spinnerSvg} className="pointer-events-none select-none" />
        </div>
      </div>
    )
  return (
    <div className="scale-75">
      <img src={status === 'need input' ? exclaimSvg : errorSvg} />
    </div>
  )
}
