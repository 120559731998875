import { authFetch } from '@services/authFetch'
import { workflowsResponseSchema } from '@types'

export const fetchAllWorkflows = async (loan_number: string) => {
  console.log('Fetching All Workflows')
  const res = await authFetch(
    `/api/encompass/workflows?loan_number=${loan_number}`
  )

  const data = (await res.json()) as { message: unknown; success: boolean }
  const parsed = workflowsResponseSchema.parse(data)
  if (parsed.success) {
    // Only return enabled workflows
    return parsed.message.filter(w => w.enabled)
  }
  // else return error message
  return parsed.message
}
