import { z } from 'zod'
import { authFetch } from './authFetch'

const transactionStatusEnumSchema = z.enum([
  'completed',
  'pending',
  'processing',
  'inputRequired',
  'canceled',
  'failed',
])

const transactionSchema = z.object({
  status: transactionStatusEnumSchema,
  updated: z.string().datetime().optional(),
  response: z.object({
    referenceNumber: z.string().optional(),
    status: transactionStatusEnumSchema.optional(),
    errors: z.array(z.object({ description: z.string() })).optional(),
    partnerStatus: z.string().optional(),
  }),
  events: z.array(
    z
      .object({
        text: z.string(),
        type: z.string(),
      })
      .optional()
  ),
  request: z
    .object({
      options: z
        .object({
          category: z.string().nullable().optional(),
        })
        .optional()
        .nullable(),
      type: z.string().optional(),
    })
    .optional()
    .nullable(),
})

const getTransaction = async (transactionId: string) => {
  console.log(`Fetching Transaction ${transactionId}`)
  const response = await authFetch(
    `/api/encompass/transactions/${transactionId}`
  )

  if (response.ok) {
    const data = (await response.json()) as { success: boolean }
    // when fetch fails it returns a 200 with success: false
    if ('success' in data && !data.success) {
      return undefined
    }
    const res = transactionSchema.parse(data)
    return res
  }
  return undefined
}

export { getTransaction }
