export class TimeoutError extends Error {}

/** @default 30_000 */
export async function rejectAfterTimeout(
  timeout_in_milliseconds: number = 30_000
): Promise<never> {
  return new Promise((_, reject) =>
    setTimeout(reject, timeout_in_milliseconds, new TimeoutError('Timed out'))
  )
}
