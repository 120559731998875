import { useEffect } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import DropdownField from './Fields/DropdownField'
import Button from './Button'
import Field from './Fields'

const DEFAULT_CONTACT = {
  relationship: '',
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  relationship_other: null,
}

/**
 * Contact Fields handles the inspector_contacts field array
 * allowing the user to add up to 3 contacts
 *
 * @todo Verify if we need to create to cover data coming in from Encompass
 * @requires useForm using react-hook-form
 */
export default function ContactFields() {
  const methods = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'inspector_contacts',
  })
  const addContactHandler = (e: React.MouseEvent) => {
    e.preventDefault()
    if (fields.length >= 3) return
    append(DEFAULT_CONTACT, { shouldFocus: false })
  }
  useEffect(() => {
    // On mount, add an empty contact
    append(DEFAULT_CONTACT, { shouldFocus: false })
    // On unmount, cleanup
    return () => {
      remove(0)
    }
  }, [append, remove])

  const rootError = methods.formState.errors.inspector_contacts?.root as
    | { message: string }
    | undefined

  return (
    <>
      <div className="mt-6 flex w-full flex-col gap-4">
        {fields.map((item, index) => {
          return (
            <div
              className="mb-8 flex flex-row items-start gap-4 last-of-type:mb-0"
              key={item.id}
            >
              <div className="grid w-full gap-4">
                <div className="inline-flex w-full items-center gap-4">
                  <h3 className="text-sm text-[#C0C0C0]">
                    CONTACT #{index + 1}
                  </h3>
                  {fields.length > 1 && (
                    <Button
                      className="text-red-500"
                      size="sm"
                      variant="text"
                      onClick={e => {
                        e.preventDefault()
                        remove(index)
                      }}
                    >
                      Remove
                    </Button>
                  )}
                </div>
                <div className="relative w-full">
                  <DropdownField
                    field={{
                      field_class: 'String',
                      name: `inspector_contacts.${index}.relationship`,
                      label: 'Relationship',
                      required: true,
                    }}
                    options={[
                      { label: 'Borrower', name: 'borrower' },
                      { label: 'Co-Borrower', name: 'coborrower' },
                      { label: 'Realtor', name: 'realtor' },
                      { label: 'Family Member', name: 'family member' },
                    ]}
                  />
                </div>
                <div className="flex w-full flex-row gap-2">
                  <div className="relative w-full">
                    <Field
                      field={{
                        field_class: 'String',
                        name: `inspector_contacts.${index}.first_name`,
                        label: 'First Name',
                        required: true,
                      }}
                    />
                  </div>
                  <div className="relative w-full">
                    <Field
                      field={{
                        field_class: 'String',
                        name: `inspector_contacts.${index}.last_name`,
                        label: 'Last Name',
                        required: true,
                      }}
                    />
                  </div>
                </div>
                <div className="flex w-full flex-row gap-2">
                  <div className="relative w-full">
                    <Field
                      field={{
                        field_class: 'Email',
                        name: `inspector_contacts.${index}.email`,
                        label: 'Email',
                        required: true,
                      }}
                    />
                  </div>
                  <div className="relative w-full">
                    <Field
                      field={{
                        field_class: 'Phone',
                        name: `inspector_contacts.${index}.phone`,
                        label: 'Phone',
                        required: true,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>

      <div className="my-4 flex w-full flex-row items-center justify-start gap-8">
        {fields.length < 3 && (
          <>
            <Button
              size="sm"
              variant="outlined"
              onClick={addContactHandler}
              disabled={fields.length >= 3}
            >
              Add Additional Contact
            </Button>
            <h3 className="text-sm font-medium italic text-gray-700">
              Maximum of 3 contacts is allowed
            </h3>
          </>
        )}
      </div>
      {rootError && (
        <p role="alert" className="px-1 text-[10px] text-red-500">
          {rootError?.message}
        </p>
      )}
    </>
  )
}
