import type { ReactNode } from 'react'

type SizeType = 'w-full' | 'w-1/2'

type Props = {
  title?: string
  children?: ReactNode
  footer?: ReactNode
  size?: {
    wrap?: SizeType
    header?: SizeType
    body?: SizeType
  }
}

export default function Card({ children, title, footer, size }: Props) {
  return (
    <>
      <div
        className={`flex ${
          size?.wrap || 'w-full'
        } flex-col items-stretch justify-start rounded-md border border-gray-200`}
      >
        {title ? (
          <header
            className={`flex ${
              size?.header || 'w-full'
            } border-b border-gray-200 bg-gray-100 p-4 px-8`}
          >
            <p className="font-bold text-gray-700 ">{title}</p>
          </header>
        ) : null}
        {children ? (
          <div
            className={`flex ${size?.body || 'w-full'} flex-col gap-4 p-8 pt-4`}
          >
            {children}
          </div>
        ) : null}
      </div>
      {footer ? (
        <div className="flex flex-row items-center gap-4 pt-8">{footer}</div>
      ) : null}
    </>
  )
}
