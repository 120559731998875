import { Navigate } from '@tanstack/react-router'
import { useMemo } from 'react'
import { CloseButton } from '@components'
import WorkflowCard from './WorkflowCard'
import { workflowsIndexRoute } from '../../router/authenticated/additionalServicesRoute/workflowRoutes'
import type { WorkflowsType } from '@types'

export default function WorkflowsIndexPage() {
  const workflows = workflowsIndexRoute.useLoaderData()

  if (workflows instanceof Response) throw new Error('500')
  if (typeof workflows === 'string') throw new Error(workflows)

  const allWorkflows = useMemo(() => {
    const next: WorkflowsType = []
    for (const workflow of workflows) {
      if (!workflow?.running_flows.length) {
        next.push(workflow)
        continue
      }

      for (let j = 0; j < workflow?.running_flows?.length; j++) {
        const running_flow = workflow?.running_flows[j]

        // To have WorkflowCard for nested running workflow
        if (running_flow)
          next.push({ ...workflow, running_flows: [running_flow] })

        if (
          running_flow?.status !== 'working' &&
          j === workflow?.running_flows.length - 1
        ) {
          // Workflow is able to be ordered again
          next.push({ ...workflow, running_flows: [] })
        }
      }
    }
    return next
  }, [workflows])

  const { loanNumber } = workflowsIndexRoute.useParams()
  if (workflows.length === 0)
    return (
      <Navigate
        to="/additional-services/workflows/no-workflows"
        search={{ disableBack: true }}
      />
    )

  return (
    <>
      <div className="grid w-full grid-cols-4 gap-4">
        {allWorkflows.map((w, index) => (
          <WorkflowCard key={index} workflow={w} loanNumber={loanNumber} />
        ))}
      </div>
      <div className="mt-8 w-full">
        <CloseButton />
      </div>
    </>
  )
}
