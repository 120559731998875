import type { Host } from '@elliemae/em-ssf-guest'
import { router } from '..'

const mockOrigin = {
  id: '_originId',
  partnerAccessToken: '_partnerToken',
  context: 'lender',
}

type RequestType = {
  request: {
    type: string
    options?: {
      vendor: string
      product: string
    }
  }
}

// TODO: remove these ts-ignores
export const DEV_HOST: Promise<Host> = new Promise(res =>
  res({
    connect: () => console.log('connecting'),
    ready: () => console.log('ready'),
    getObject: (key: 'transaction' | 'application') => {
      console.log(`Getting {${key}} object...`)
      switch (key) {
        case 'transaction':
          return new Promise(res => {
            res({
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              refreshOrigin: async () =>
                new Promise(res => {
                  console.log('refreshing origin')
                  res(mockOrigin)
                }),
              getOrigin: async () =>
                new Promise(res => {
                  console.log('refreshing origin')
                  res(mockOrigin)
                }),
              create: (request: RequestType) => {
                console.log('Creating\n', request)
                return Promise.resolve({
                  id: 'TRANSACTION_GUID',
                })
              },
              close: async () => {
                console.log('Closing Transaction')
                await router.navigate({ to: '/dev' })
              },
              update: (request: RequestType) => {
                console.log('Updating\n', request)
                return Promise.resolve({
                  id: 'TRANSACTION_GUID',
                })
              },
            })
          })
        case 'application':
          return new Promise(res => {
            res({
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              getCapabilities: async () =>
                new Promise(res => {
                  console.log('Getting Capabilities')
                  res({})
                }),
              open: async () =>
                new Promise<void>(res => {
                  console.log('Application Open')
                  res()
                }),
              openModal: async () =>
                new Promise<void>(res => {
                  console.log('Application Open Modal')
                  res()
                }),
            })
          })
      }
    },
    setLogLevel: () => console.log('setLogLevel'),
    subscribe: () => 0,
    unsubscribe: () => console.log('unsubscribe'),
  })
)
